<template>
	<div class="TemplateEdit__warp">
		<x-header back customBack @back="$router.push(`/manager/template`)">模板编辑</x-header>
		<fc-designer ref="designer" :menu="menu" height="calc(100% - 50px)">
			<template slot="handle">
				<ElButton size="mini" round type="success" @click="saveForm"> 保 存 </ElButton>
			</template>
		</fc-designer>
		<AppDialog  ref="appDialog" originTitle dialogTitle="保存模板" >
			<el-form ref="form" :model="form" :rules="formRule">
				<el-form-item prop="name">
					<el-input placeholder="模板名称(必填)" v-model="form.name"></el-input>
				</el-form-item>
				<el-form-item prop="domainId">
					<el-select  filterable  alwaysShowClear clearable v-model="form.domainId"  placeholder="学科领域(必填)">
							<el-option :value="item.id" :label="item.name" v-for="(item,index) in domainList" :key="item.id"></el-option>
						</el-select>
				</el-form-item>
				
				<el-form-item>
					<el-input placeholder="模板描述" type="textarea" v-model="form.remarks"></el-input>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" style="float: right;" @click="submit">保存</el-button>
				</el-form-item>
			</el-form>
		</AppDialog>
	</div>
</template>

<script>
	import FcDesigner from '@form-create/designer'
	import {Save,Detail} from "@/service/formTemplate";
	import formCreate from "@form-create/element-ui";
	import {GroupMixins} from "@/mixins/groupIndex";
	import xUploadRule from "@/components/formRules/XUploadRule";
	import xKetcherRule from '@/components/formRules/XKetcherRule';
	import xCkeditorRule from '@/components/formRules/XCkeditorRule';
	import xVueesignRule from '@/components/formRules/XVueesignRule';
	import xVditorRule from '@/components/formRules/XVditorRule';
	import {sysSettingAllList} from "../../service/sysSetting"

	export default {
		name: "TemplateEdit",
		mixins:[],
		components:{FcDesigner},
		data(){
			return {
				form:{
					id:'',
					name:'',
					remarks:'',
					domainId:'',
				},
				domainList:[],
				sysSettingType:'domain',
				formRule:{
				name:[{ required: true, message: '请填写模板名称', trigger: 'blur' }],
				domainId:[{ required: true, message: '请选择学科领域', trigger: 'change' }]

				},
				menu:[
					/**{
					title: '布局组件',
					name:'group',
					list:[{
						icon: 'icon-row',
						name: 'row',
						label: '栅格布局'
					},{
						icon: 'icon-space',
						name: 'div',
						label: '间隔'
					}]
				},*/{
					title:'组件',
					name:'group1',
					list:[{
						icon: 'icon-input',
						name: 'input',
						label: '文本输入'
					},{
						icon: 'icon-radio',
						name: 'radio',
						label: '单选项 '
					},{
						icon: 'icon-checkbox',
						name: 'checkbox',
						label: '多选项 '
					},{
						icon: 'icon-select',
						name: 'select',
						label: '下拉选项'
					},{
						icon: 'icon-switch',
						name: 'switch',
						label: '开关'
					},{
						icon: 'icon-date',
						name: 'datePicker',
						label: '日期选择'
					},{
						icon: 'icon-time',
						name: 'timePicker',
						label: '时间选择'
					},{
						icon: 'icon-rate',
						name: 'rate',
						label: '评分'
					}]
				}]
			}
		},
		created() {

		},
		mounted() {
			this.getDomainList();
			this.form.id = this.$route.query.id || ''
			this.$watch(
				() => {
					return this.$refs.designer.activeRule
				},
				(val) => {
					if(val){
						if(this.FORM_EXPERIMENT_FIELD.includes(val.field)){
							this.$nextTick(() => {
								this.$refs.designer.activeRule = null
								this.$refs.designer.activeTab = 'form'
							})
						}
					}
				}
			)
			if(this.form.id){
				this.getDetail();
			}else{
				this.initTemplate();
			}
		},
		methods:{
				getDomainList(){
        sysSettingAllList(this.sysSettingType).then(e => {
          let data = this.APIParse(e);
          this.domainList=data
					data.forEach(item => {
						this.$set(this.domainNameMap,item.id,item.name)
					})
        })
			},
			roleLoadSuccess(){
				if(!this.IS_LEADER && !this.IS_MANAGER){
					this.$router.push('/manager/group');
				}
			},
			saveForm(){
				let rule = this.$refs.designer.getRule();
				if(rule.length == 0){
					this.$message.warning('请设计表单');
					return;
				}else{
					this.$refs.appDialog.show();
				}
			},
			submit(){
				this.$refs.form.validate((valid) => {
					if (valid) {
						let option = this.$refs.designer.getOption();
						let rule = this.$refs.designer.getRule();		
						Save({
							id:this.form.id,
							name:this.form.name,
							remarks:this.form.remarks,
							domainId:this.form.domainId,
							rule:formCreate.toJson(rule),
							options:JSON.stringify(option),
							//groupId:this.groupId
						}).then(e => {
							
							let data = this.APIParse(e);
							if(data){
								this.$message.success('模板保存成功');
								this.$router.push('/manager/template')
							}else{
								this.$message.error('模板保存失败,请重试');
							}
						}).catch(ex => {
							this.$message.error('模板保存失败,请重试');
						})
					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},
			getDetail(){
				Detail(this.form.id).then(e => {
					let data = this.APIParse(e);
					this.form.name = data.name
					this.form.remarks = data.remarks
					this.form.domainId=data.domainId
					this.initTemplate(data)
				}).catch(ex => {
					this.$message.error('模板加载失败')
					this.$router.push('/manager/template')
				})
			},
			initTemplate(data){
				//插入组件规则
				this.$refs.designer.addComponent(xUploadRule);
				this.$refs.designer.addComponent(xKetcherRule);
				this.$refs.designer.addComponent(xCkeditorRule);
				this.$refs.designer.addComponent(xVditorRule);
				this.$refs.designer.addComponent(xVueesignRule);

				//左侧隐藏富文本编辑，默认放在表单中，重复加入只能显示第一个富文本
				/**this.$refs.designer.appendMenuItem('group1', {
				 	icon: xCkeditorRule.icon,
				 	name: xCkeditorRule.name,
				 	label: xCkeditorRule.label
				})*/
				this.$refs.designer.appendMenuItem('group1', {
				 	icon: xVditorRule.icon,
				 	name: xVditorRule.name,
				 	label: xVditorRule.label
				})
				this.$refs.designer.appendMenuItem('group1', {
				 	icon: xKetcherRule.icon,
				 	name: xKetcherRule.name,
				 	label: xKetcherRule.label
				})
				this.$refs.designer.appendMenuItem('group1', {
				 	icon: xVueesignRule.icon,
				 	name: xVueesignRule.name,
				 	label: xVueesignRule.label
				})
				
				//插入拖拽按钮到`main`分类下
				// this.$refs.designer.appendMenuItem('group1', {
				// 	icon: xUploadRule.icon,
				// 	name: xUploadRule.name,
				// 	label: xUploadRule.label
				// })
				//删除基础规则中的数据关联和按钮
				this.$refs.designer.baseForm.rule.splice(3,2)
				let requiedFields = this.FORM_EXPERIMENT_FIELD_TEMPLATE;
				let rule = [{
					type:"input",
					title:"实验名称",
					field:"subject",
					value:"",
					props: {
						type: "text",
					},
					validate:[
						{ required: true, message: '请输入实验名称', trigger: 'blur' },
					]
				},{
					type:"datePicker",
					title:"实验时间",
					field:"date",
					value:"",
					/**validate:[
						{ required: true, message: '请选择实验时间', trigger: 'blur' },
					]*/
				},/**{
					type:"input",
					title:"实验内容",
					field:"content",
					value:"",
					props: {
						type: "textarea",
					},
				},{
					type:"input",
					title:"实验结果",
					field:"result",
					value:"",
					props: {
						type: "textarea",
					}
				},{
					type:"input",
					title:"实验地点",
					field:"ins",
					value:""
					//value:this.user.institution
				},*/
				{
					type:"x-ckeditor",
					title:"实验内容",
					field:"editerHtml",
					value:''
				},
					/**{
					type:"x-vditor",
					title:"markDown",
					field:"markDown",
					value:''
				},
			{
					type:"x-ketcher",
					title:"分子式",
					field:"fenzi",
					value:{
						mol:'',
						png:''
					}
				},*/
				{
					type:"x-upload",
					title:"附件",
					field:"annex",
					value:[]
				}];
				let options;
				if(data){
					rule = formCreate.parseJson(data.rule)
					options = JSON.parse(data.options)
				}else{
					options = this.$refs.designer.getOption();
				}
				let requiedIndexs = rule.map((e,index) => {
					if(requiedFields.includes(e.field)){
						return index
					}
					return -1
				}).filter(e => e != -1)
				this.$refs.designer.setRule(rule)
				if(options){
					options.submitBtn = false
					this.$refs.designer.setOption(options)
				}
				this.$nextTick(() => {
					//必填项去除右下角操作按钮
					document.querySelectorAll('.drag-tool[unique]').forEach((dom,index) => {
						if(requiedIndexs.includes(index)){
							dom.querySelector('.drag-r').remove();
						}
					})
					
					//去除清空按钮
					document.querySelectorAll('._fc-m-tools button.base-button')[2].remove();
					//去除预览按钮
					//document.querySelectorAll('._fc-m-tools button.base-button')[1].remove();
				})
			}
		}
	}
</script>

<style lang="scss" scoped>
	.TemplateEdit__warp{width: 100%;height: 100%;overflow: auto;}
</style>
<style lang="scss">
	.TemplateEdit__warp ._fc-l{width: 166px !important;}
	.TemplateEdit__warp ._fc-r{width: 236px !important;}
	.TemplateEdit__warp .drag-tool .drag-mask{z-index: 0 !important;}
//隐藏右侧tab
.TemplateEdit__warp ._fc-r .el-header ._fc-r-tab{
	display: none;
}
//隐藏表单配置内容
.TemplateEdit__warp ._fc-r .el-main:nth-of-type(1){
	display: none;
}


//隐藏组件配置第一项的id配置和提示信息
.TemplateEdit__warp ._fc-r .el-main:nth-of-type(2) .base-form:nth-of-type(1) .base-col-24:nth-of-type(1),
.TemplateEdit__warp ._fc-r .el-main:nth-of-type(2) .base-form:nth-of-type(1) .base-col-24:nth-of-type(3){
	display: none;
	}
.TemplateEdit__warp ._fc-r .el-main:nth-of-type(2) .base-form:nth-of-type(2) .base-col-24:nth-of-type(n+8){
	display: none;
}
.TemplateEdit__warp ._fc-r .el-main:nth-of-type(2) .base-form:nth-of-type(2) .base-col-24:nth-of-type(2) .base-form-item__content .base-radio-group{
	display: none;
}
//隐藏组件配置属性配置校验规则名称
.TemplateEdit__warp ._fc-r .el-main .base-divider:nth-child(3),
.TemplateEdit__warp ._fc-r .el-main .base-divider:nth-child(5){
	display: none;
}
._fc-m-drag .base-form-item--mini .base-form-item__label{
	width: 100px !important;
}
._fc-m-drag .base-form-item--mini .base-form-item__content{
	margin-left: 100px !important;
}
.el-main{
	padding: 20px 10px !important;
}
</style>